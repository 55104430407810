/* 1) RESET & BODY */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  font-family: 'Open Sans', Arial, sans-serif;
  background: #f8f8f8;
  color: #444;
  line-height: 1.6;
  scroll-behavior: smooth;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: #ff6600;
  transition: all 0.3s ease;
}

a:hover {
  color: #e65c00;
}

/* 2) CONTAINERS & UTILITIES */
.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.section {
  padding: 4rem 0;
}

.section-header {
  text-align: center;
  margin-bottom: 2.5rem;
}

.section-header h2 {
  font-size: 2.2rem;
  margin-bottom: 0.5rem;
  position: relative;
  display: inline-block;
}

.section-header h2 span {
  color: #ff6600;
}

.section-divider {
  width: 80px;
  height: 3px;
  background: #ff6600;
  margin: 0.5rem auto 1.5rem;
}

.section-subheader {
  max-width: 700px;
  margin: 0 auto;
  font-size: 1.1rem;
  color: #666;
}

.alt-bg {
  background: #f0f0f0;
}

.button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background: #ff6600;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  transition: all 0.3s ease;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.button:hover {
  background: #e65c00;
  color: #fff;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

/* 3) TOP BAR */
.top-bar {
  background: #ff6600;
  color: #fff;
  padding: 0.5rem 0;
}

.top-bar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}

.years-in-business {
  font-size: 0.9rem;
  letter-spacing: 0.5px;
}

.phone-number {
  font-size: 1rem;
}

.top-bar a {
  color: #fff;
  text-decoration: none;
}

/* 4) HEADER / NAVBAR */
.header {
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: sticky;
  top: 0;
  z-index: 100;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
}

.logo {
  font-size: 1.5rem;            /* Reduced from 1.75rem */
  font-weight: bold;
  background-color: #ff6600;    /* Solid orange background */
  color: black;                 /* Black text */
  padding: 0.4rem 0.8rem;        /* Slightly smaller padding */
  display: inline-block;        /* Wrap background around text only */
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);  /* Subtle box-shadow */
  text-shadow: 0.8px 0.8px 1.5px rgba(0, 0, 0, 0.4); /* Subtle text-shadow */
}



.nav-links {
  display: flex;
  gap: 1.5rem;
}

.nav-links a {
  color: #333;
  font-weight: 600;
  position: relative;
  padding: 0.5rem 0;
}

.nav-links a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: #ff6600;
  transition: width 0.3s ease;
}

.nav-links a:hover {
  color: #ff6600;
}

.nav-links a:hover::after {
  width: 100%;
}

/* 5) HERO SECTION */
.hero-section {
  height: 600px;
  background: #333;  /* Fallback background color */
  background-size: cover;
  background-position: center;
  position: relative;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 1;
}

.hero-overlay {
  max-width: 800px;
  padding: 2rem;
  position: relative;
  z-index: 2;
}

.hero-overlay h1 {
  font-size: 2.8rem;
  margin-bottom: 1rem;
  line-height: 1.2;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.5);
}

.hero-overlay p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
}

.hero-buttons {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
}

.primary-button {
  padding: 1rem 2rem;
  background: #ff6600;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0,0,0,0.3);
}

.primary-button:hover {
  background: #e65c00;
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.3);
}

.pulse-button {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 102, 0, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 102, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 102, 0, 0);
  }
}

.secondary-button {
  padding: 1rem 2rem;
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  border: 2px solid #fff;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.secondary-button:hover {
  background: rgba(255, 255, 255, 0.3);
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.3);
}

/* 5.5) SEALCOATING HIGHLIGHT SECTION */
.sealcoating-highlight {
  background: #fff;
  position: relative;
  overflow: hidden;
}

.sealcoating-benefits {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  margin: 3rem 0;
}

.benefit-item {
  flex: 1;
  text-align: center;
  padding: 2rem;
  background: #f8f8f8;
  border-radius: 8px;
  transition: all 0.3s ease;
  box-shadow: 0 3px 10px rgba(0,0,0,0.1);
}

.benefit-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}

.benefit-icon {
  font-size: 2.5rem;
  color: #ff6600;
  margin-bottom: 1rem;
  height: 60px;
  width: 60px;
  line-height: 60px;
  border-radius: 50%;
  background: #fff;
  margin: 0 auto 1rem;
  box-shadow: 0 5px 15px rgba(255, 102, 0, 0.2);
}

.benefit-item h3 {
  margin-bottom: 0.75rem;
  color: #333;
  font-size: 1.3rem;
}

.cta-container {
  text-align: center;
  padding: 2rem;
  background: #f8f8f8;
  border-radius: 8px;
  margin-top: 2rem;
  box-shadow: 0 3px 10px rgba(0,0,0,0.1);
}

.cta-container p {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

.cta-button {
  padding: 1rem 2rem;
  background: #ff6600;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.cta-button:hover {
  background: #e65c00;
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.3);
}

/* 5.5) FEATURED PROJECTS */
.featured-projects {
  background: #fff;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 2rem;
}

.project-item {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

.project-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0,0,0,0.15);
}

.project-image {
  height: 200px;
  overflow: hidden;
}

.project-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.project-item:hover .project-image img {
  transform: scale(1.05);
}

.project-info {
  padding: 1.5rem;
}

.project-info h3 {
  color: #ff6600;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}

/* 6) SERVICES SECTION */
.service-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.service-item {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 8px rgba(0,0,0,0.05);
  padding: 0;
  transition: all 0.3s ease;
  border-bottom: 3px solid #ff6600;
  overflow: hidden;
  position: relative;
}

.service-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.featured-service {
  box-shadow: 0 5px 20px rgba(255, 102, 0, 0.15);
  transform: translateY(-5px);
  border: 1px solid rgba(255, 102, 0, 0.1);
  border-bottom: 3px solid #ff6600;
}

.featured-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ff6600;
  color: white;
  padding: 5px 10px;
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 3px;
  z-index: 10;
}

.service-image {
  height: 200px;
  overflow: hidden;
}

.service-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.service-item:hover .service-image img {
  transform: scale(1.05);
}

.service-item h3 {
  color: #ff6600;
  margin: 1.2rem 1.5rem 0.7rem;
  font-size: 1.3rem;
}

.service-item p {
  padding: 0 1.5rem;
  margin-bottom: 1.5rem;
}

.service-button {
  display: inline-block;
  margin: 0 1.5rem 1.5rem;
  padding: 0.7rem 1.5rem;
  background: #ff6600;
  color: #fff;
  border-radius: 4px;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.service-button:hover {
  background: #e65c00;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  color: #fff;
}

.nav-link-button {
  background: none;
  border: none;
  font: inherit;
  color: inherit;
  cursor: pointer;
  padding: 0;
  text-decoration: none;
}

.nav-link-button:hover {
  text-decoration: underline;
}

/* Contact Form CSS */
.contact-container {
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
}

.contact-info {
  flex: 1;
}

.contact-form {
  flex: 1;
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.contact-form h3 {
  margin-bottom: 1.5rem;
  color: #333;
  font-size: 1.5rem;
}

.form-row {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.form-group {
  flex: 1;
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #444;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #ff6600;
  outline: none;
  box-shadow: 0 0 0 3px rgba(255, 102, 0, 0.1);
}

.submit-button {
  background: #ff6600;
  color: #fff;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  margin-top: 1rem;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.submit-button:hover {
  background: #e65c00;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.15);
}

.form-disclaimer {
  font-size: 0.85rem;
  color: #777;
  margin-top: 1rem;
  text-align: center;
}

.form-success {
  background: #f8f8f8;
  border-left: 4px solid #ff6600;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
}

.form-success h3 {
  color: #ff6600;
  margin-bottom: 1rem;
}

/* About section */
.about-content {
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
}

.about-image {
  flex: 1;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.about-text {
  flex: 1;
}

.about-text p {
  margin-bottom: 1.5rem;
}

.experience-badge {
  position: absolute;
  top: 20px;
  right: 20px;
  background: #ff6600;
  color: #fff;
  padding: 1rem;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.experience-badge .years {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1;
}

.experience-badge .text {
  font-size: 0.8rem;
  text-transform: uppercase;
  line-height: 1;
}

.about-cta button {
  padding: 0.75rem 1.5rem;
  background: #ff6600;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.about-cta button:hover {
  background: #e65c00;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.15);
}

/* Reviews Section */
.reviews-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 2rem;
}

.review-box {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.05);
  transition: all 0.3s ease;
}

.review-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0,0,0,0.1);
}

.review-box .stars {
  color: #ff6600;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.review-box blockquote {
  font-style: italic;
  color: #666;
}

.review-box cite {
  display: block;
  margin-top: 0.5rem;
  font-weight: bold;
  color: #333;
  font-style: normal;
}

.review-cta {
  text-align: center;
  margin-top: 3rem;
}

.review-cta p {
  margin-bottom: 1rem;
}

/* Footer */
.footer {
  background: #333;
  color: #fff;
  padding: 3rem 0 2rem;
}

.footer-content {
  display: flex;
  justify-content: space-between;
}

.footer-info {
  flex: 2;
}

.footer-info p {
  margin-bottom: 0.5rem;
  color: #ddd;
}

.footer-links {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.footer-links a {
  color: #ddd;
  margin-bottom: 0.75rem;
  transition: color 0.3s;
}

.footer-links a:hover {
  color: #ff6600;
}

/* Quote Section */
.quote-section {
  background: #f8f8f8;
  position: relative;
  overflow: hidden;
}

.quote-container {
  margin-top: 2rem;
  background: #fff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.05);
}

/* Job Application Form */
.job-form-container {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  margin-top: 2rem;
}

/* Mobile Menu Styles */
.mobile-nav-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #ff6600;
  cursor: pointer;
  z-index: 200;
}

/* Hamburger Icon */
.hamburger {
  display: inline-block;
  width: 30px;
  height: 20px;
  position: relative;
}

.hamburger span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #ff6600;
  border-radius: 3px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: all 0.25s ease-in-out;
}

.hamburger span:nth-child(1) {
  top: 0px;
}

.hamburger span:nth-child(2) {
  top: 8px;
}

.hamburger span:nth-child(3) {
  top: 16px;
}

/* X Icon (when menu is open) */
.mobile-nav-open .hamburger span:nth-child(1) {
  top: 8px;
  transform: rotate(135deg);
}

.mobile-nav-open .hamburger span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.mobile-nav-open .hamburger span:nth-child(3) {
  top: 8px;
  transform: rotate(-135deg);
}

/* Fix for smooth scrolling with fixed header */
html {
  scroll-padding-top: 80px; /* Adjust this value based on your header height */
}

/* General Mobile Responsive Adjustments */
@media (max-width: 992px) {
  .container {
    width: 95%;
  }
  
  .section {
    padding: 3rem 0;
  }
  
  .section-header h2 {
    font-size: 1.8rem;
  }
  
  .hero-overlay h1 {
    font-size: 2.2rem;
  }
  
  .hero-section {
    height: 500px;
  }
  
  .projects-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .reviews-content {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  /* Mobile Navigation */
  .mobile-nav-toggle {
    display: flex;
    align-items: center;
  }
  
  .header-content {
    position: relative;
  }
  
  .nav-links {
    position: fixed;
    top: 0;
    right: -100%;
    width: 80%;
    max-width: 300px;
    height: 100vh;
    background: #fff;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    transition: right 0.3s ease;
    box-shadow: -5px 0 15px rgba(0,0,0,0.1);
    z-index: 100;
    padding: 2rem;
  }
  
  .mobile-nav-open .nav-links {
    right: 0;
  }
  
  .nav-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 90;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
  }
  
  .mobile-nav-open .nav-overlay {
    opacity: 1;
    visibility: visible;
  }
  
  /* Typography adjustments for mobile */
  .section-header h2 {
    font-size: 1.6rem;
  }
  
  .hero-overlay h1 {
    font-size: 1.8rem;
  }
  
  .hero-overlay p {
    font-size: 1rem;
  }
  
  .hero-section {
    height: 400px;
  }
  
  /* Structure adjustments */
  .top-bar-content {
    flex-direction: column;
    gap: 0.5rem;
    text-align: center;
    padding: 0.5rem 0;
  }
  
  .sealcoating-benefits {
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .about-content {
    flex-direction: column;
  }
  
  .about-image, .about-text {
    width: 100%;
  }
  
  .reviews-content {
    grid-template-columns: 1fr;
  }
  
  .contact-container {
    flex-direction: column;
  }
  
  .contact-info, .contact-form {
    width: 100%;
  }
  
  .hero-buttons {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
  
  .projects-grid {
    grid-template-columns: 1fr;
  }
  
  /* Form adjustments */
  .form-row {
    flex-direction: column;
  }
  
  .form-group {
    width: 100%;
  }
  
  .footer-content {
    flex-direction: column;
  }
  
  .footer-info {
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .footer-links {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .job-form-container iframe {
    height: 1200px; /* Increase height on mobile for better layout */
  }
  
  .quote-container iframe {
    height: 600px; /* Adjust height for mobile */
  }
  
  .experience-badge {
    width: 80px;
    height: 80px;
    padding: 0.75rem;
  }
  
  .experience-badge .years {
    font-size: 1.5rem;
  }
  
  .experience-badge .text {
    font-size: 0.7rem;
  }
}

@media (max-width: 480px) {
  .section {
    padding: 2rem 0;
  }
  
  .primary-button, .secondary-button {
    width: 100%;
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }
  
  .section-header h2 {
    font-size: 1.4rem;
  }
  
  .hero-overlay h1 {
    font-size: 1.6rem;
  }
  
  .hero-section {
    height: 350px;
  }
}


/* Add these CSS rules to fix scroll positioning */

/* Better approach using scroll-padding-top to handle fixed header offset */
html {
  scroll-padding-top: 100px; /* Increase this value - it should be slightly larger than your header height */
  scroll-behavior: smooth;
}

/* More specific anchors for sections */
section[id] {
  scroll-margin-top: 100px; /* Same value as scroll-padding-top */
  position: relative;
}

/* Optional: Add this if sections still scroll incorrectly */
section[id]::before {
  content: "";
  display: block;
  height: 100px; /* Adjust to match header height + buffer */
  margin-top: -100px;
  visibility: hidden;
  pointer-events: none;
}

