/* Contact Form Styles */
.contact-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin-top: 2rem;
  }
  
  .contact-info {
    flex: 1;
    min-width: 300px;
  }
  
  .contact-form {
    flex: 1;
    min-width: 300px;
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  }
  
  .contact-form h3 {
    margin-bottom: 1.5rem;
    color: #333;
    font-size: 1.3rem;
    text-align: center;
    position: relative;
  }
  
  .contact-form h3:after {
    content: '';
    display: block;
    width: 50px;
    height: 3px;
    background: #ff6600;
    margin: 0.5rem auto 0;
  }
  
  .form-group {
    margin-bottom: 1.2rem;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 600;
    color: #333;
  }
  
  .form-group input,
  .form-group select,
  .form-group textarea {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-family: inherit;
    font-size: 1rem;
    transition: border-color 0.3s ease;
  }
  
  .form-group input:focus,
  .form-group select:focus,
  .form-group textarea:focus {
    border-color: #ff6600;
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 102, 0, 0.2);
  }
  
  .submit-button {
    display: block;
    width: 100%;
    padding: 1rem;
    background: #ff6600;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 3px 6px rgba(0,0,0,0.2);
    margin-top: 1rem;
  }
  
  .submit-button:hover {
    background: #e65c00;
    transform: translateY(-2px);
    box-shadow: 0 5px 12px rgba(0,0,0,0.3);
  }
  
  .form-success {
    background: #f8f8f8;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  }
  
  .form-success h3 {
    color: #ff6600;
    margin-bottom: 1rem;
  }
  
  /* Make form responsive */
  @media (max-width: 768px) {
    .contact-container {
      flex-direction: column;
    }
    
    .contact-info,
    .contact-form {
      width: 100%;
    }
  }
  
  /* Add specific styles for the free quote section */
  .quote-section {
    background: #f0f0f0;
    padding: 3rem 0;
  }
  
  .quote-container {
    max-width: 800px;
    margin: 0 auto;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    padding: 2rem;
  }
  
  .quote-title {
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 1.5rem;
    color: #333;
    position: relative;
  }
  
  .quote-title:after {
    content: '';
    display: block;
    width: 60px;
    height: 3px;
    background: #ff6600;
    margin: 0.5rem auto 0;
  }
  
  .quote-form .form-row {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 1.2rem;
  }
  
  .quote-form .form-group {
    flex: 1;
  }
  
  .quote-cta {
    text-align: center;
    margin-top: 1.5rem;
  }
  
  @media (max-width: 768px) {
    .quote-form .form-row {
      flex-direction: column;
      gap: 0;
    }
  }